import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

// layouts
import AppLayout from '../../../layouts/AppLayout/AppLayout';

// components
import { NioSection, NioMedia, NioButton, NioCount, NioCard } from '../../../components';


function index() {

  return (
    <AppLayout variant={5} title="广东喆事宝贸易有限公司" rootClass="layout-4">

      {/*   Banner Section Start  */}
      <section className="nk-banner nk-banner-business-expance-tracker bg-primary-gradient-soft">
        <div className="nk-banner-wrap">
          <Container>
            <Row className="flex-row-reverse align-items-center justify-content-between">
              <Col lg={6}>
                <div className="nk-banner-img mb-5  mb-lg-0" >
                  <img src="images/business-tracker/banner-cover.png" alt="banner-cover" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="nk-banner-content">
                  <h1 className="display-6 mb-2">喆事宝携初心而行，绘百年宏图。</h1>
                  <p className="fs-20 mb-0" >我们寻觅、开发、推广及提供销售解决方案，借以为企业及消费者创造消费的体验。核心业务包括进出口贸易、食品及饮料贸易及经销，业务遍布中国及中国香港、马来西亚、泰国、新加波、菲律宾。</p>
                  
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/*  Banner Section End   */}

      {/*  Feature Section Start   */}
      <NioSection className="nk-feature-section pb-7 pb-lg-10">
        <NioSection.Head alignX="center">
          <h2>探索我们的业务板块</h2>
          <p className="fs-20 mb-0">进一步了解我们的行业解决方案如何帮助食品和饮料细分市场的公司实现其业务目标</p>
        </NioSection.Head>
        <NioSection.Content>
          <Row className="gap g-5">
          <Col sm={6} lg={4} >
              <div className="text-center">
                <div className="mb-3 mb-md-5">
                  <img src="images/business-tracker/section-cover-1-b.png" alt="cover" />
                </div>
                <div className="mb-4">
                  <h4 className="text-capitalize">食品批发</h4>
                  <p className="fs-16 line-clamp-2 fw-medium">喆事宝于食品饮料类专营全球各地知名饼干、糖果、巧克力、饮料、冲泡饮品等，服务对象包括国内外的超级市场、大卖场、食品批发商、线上卖场等</p>
                </div>
                <NioButton href="/" className="btn-link text-primary-alt d-inline-block" label="详细查看" icon="arrow-right after" />
              </div>
            </Col>
            <Col sm={6} lg={4} >
              <div className="text-center">
                <div className="mb-3 mb-md-5">
                  <img src="images/business-tracker/section-cover-1.png" alt="cover" />
                </div>
                <div className="mb-4">
                  <h4 className="text-capitalize">食品进出口及贸易</h4>
                  <p className="fs-16 line-clamp-2 fw-medium">依托喆事宝国际贸易资源和近年来国际物流发展的优势，发展进出口预包装产业供应链，是喆事宝核心业务板块。</p>
                </div>
                <NioButton href="/" className="btn-link text-primary-alt d-inline-block" label="详细查看" icon="arrow-right after" />
              </div>
            </Col>
            <Col sm={6} lg={4} >
              <div className="text-center">
                <div className="mb-3 mb-md-5">
                  <img src="images/business-tracker/section-cover-1-a.png" alt="cover" />
                </div>
                <div className="mb-4">
                  <h4 className="text-capitalize">食品电子商贸</h4>
                  <p className="fs-16 line-clamp-2 fw-medium">依托强大的食品品牌产业链和互联网电商产业平台，喆事宝致力于成为用户发现并获得优价好物的首选合作方。</p>
                </div>
                <NioButton href="/" className="btn-link text-primary-alt d-inline-block" label="详细查看" icon="arrow-right after" />
              </div>
            </Col>
            <Col sm={6} lg={4} >
              <div className="text-center">
                <div className="mb-3 mb-md-5">
                  <img src="images/business-tracker/section-cover-1-b.png" alt="cover" />
                </div>
                <div className="mb-4">
                  <h4 className="text-capitalize">食品供应链</h4>
                  <p className="fs-16 line-clamp-2 fw-medium">在消费者趋势迅速变化的时代，预包装食品供应商面临着在越来越短的时间内高效地的挑战。</p>
                </div>
                <NioButton href="/" className="btn-link text-primary-alt d-inline-block" label="详细查看" icon="arrow-right after" />
              </div>
            </Col>
            <Col sm={6} lg={4} >
              <div className="text-center">
                <div className="mb-3 mb-md-5">
                  <img src="images/business-tracker/section-cover-1-b.png" alt="cover" />
                </div>
                <div className="mb-4">
                  <h4 className="text-capitalize">绿色健康食品</h4>
                  <p className="fs-16 line-clamp-2 fw-medium">基于营养、质构、风味等方面的综合分析，种植加工/精益筛选/批发零售/仓储物流/进出口。</p>
                </div>
                <NioButton href="/" className="btn-link text-primary-alt d-inline-block" label="详细查看" icon="arrow-right after" />
              </div>
            </Col>
            <Col sm={6} lg={4} >
              <div className="text-center">
                <div className="mb-3 mb-md-5">
                  <img src="images/business-tracker/section-cover-1-b.png" alt="cover" />
                </div>
                <div className="mb-4">
                  <h4 className="text-capitalize">仓储及物流配送</h4>
                  <p className="fs-16 line-clamp-2 fw-medium">喆事宝拥有丰富的经验及专业的仓库及物流温控技术，包括存仓、冻仓、货运、拖运、装箱拆箱、装货卸货、包装以及各式各样的相关增值服务。</p>
                </div>
                <NioButton href="/" className="btn-link text-primary-alt d-inline-block" label="详细查看" icon="arrow-right after" />
              </div>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Feature Section End   */}

      {/*  Testimonial Section Start   */}
      <NioSection className="bg-blue-50">
        <Row className="justify-content-center">
          <Col xl={7}>
            <div className="nk-section-head pb-7 text-center">
              <h2>营销和销售</h2>
              <p className="fs-20 mb-0">
              产品到市场无缝衔接，与我们一起创新、成长、成功。
              </p>
            </div>
          </Col>
        </Row>
        <NioSection.Content>
          <Row className="gy-5">
            <Col lg={6} >
              <NioCard className="nk-feature-block overflow-hidden border-0 bg-blue-50 rounded-2 h-100">
                <NioCard.Body>
                  <div className="nk-feature-block-content h-100">
                    <NioMedia size="lg" rounded variant="primary" icon="users-fill" className="mb-3 mb-md-5" />
                    <h4>合作品牌</h4>
                    <p className="fs-18 mb-0">
                    我们相信，通过广东喆事宝贸易有限公司的不断丰富和发展，我们不仅能够为消费者带来更丰富的产品选择，同时也能为我们的合作伙伴打造一片更广阔的发展天地。
                    </p>
                    <NioButton href="/" className="btn-link text-primary pt-5" label="访问产品库" icon="arrow-right after" />
                  </div>
                </NioCard.Body>
                <div className="text-start">
                  <img src="images/features/a.png" alt="feature" className="pe-3 pe-md-7" />
                </div>
              </NioCard>
            </Col>
            <Col lg={6} >
              <NioCard className="nk-feature-block overflow-hidden border-0 bg-blue-50 rounded-2 h-100">
                <NioCard.Body>
                  <div className="nk-feature-block-content h-100">
                    <NioMedia size="lg" rounded variant="danger text-white" icon="help-fill" className="mb-3 mb-md-5" />
                    <h4>新闻中心</h4>
                    <p className="fs-18 mb-0">
                    我们致力于为您提供最新鲜、最权威的行业动态，以及我们公司和合作伙伴的最新发展。建立一个信息共享平台，让客户、合作伙伴以及关注我们的社会各界人士能够第一时间获得最有价值的资讯。
                    </p>
                    <NioButton href="/" className="btn-link text-primary pt-5" label="访问新闻中心" icon="arrow-right after" />
                  </div>
                </NioCard.Body>
                <div className="text-end">
                  <img src="images/features/b.png" alt="feature" className="ps-3 ps-md-7" />
                </div>
              </NioCard>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Testimonial Section End   */}



      {/*  Process Section Start   */}
      <NioSection className="nk-process-section py-7 py-lg-120">
        <Row className="justify-content-center">
          <Col lg={8} xl={6}>
            <div className="nk-section-head pb-7 pb-lg-120 text-center">
              <h2>服务流程</h2>
              <p className="fs-20 mb-0">喆事宝整合各专业各部门人员，打破传统孤岛和业务壁垒，赋能食品企业以全新的方式学习交流和批发零售贸易。</p>
            </div>
          </Col>
        </Row>
        <NioSection.Content>
          <ul className="nk-schedule d-flex flex-column gap-5 gap-md-7 gap-lg-120 nk-schedule-s1">
            <li className="nk-schedule-item p-0">
              <div className="nk-schedule-item-inner">
                <div className="nk-schedule-symbol">
                  <NioMedia size="lg" rounded variant="primary-soft" className="fw-bold">
                    1
                  </NioMedia>
                </div>
                <div className="nk-schedule-content">
                  <Row className="flex-row-reverse justify-content-between">
                    <Col lg={7} >
                      <div className="nk-feature-overview-img text-lg-end mb-n2 mb-lg-n0 mt-lg-n9">
                        <img src="images/business-tracker/section-cover-2-a.png" alt="cover-bg" />
                        <img src="images/business-tracker/section-cover-2.png" alt="cover-fd" className="children children-center" />
                      </div>
                    </Col>
                    <Col lg={5} >
                      <div className="nk-section-head">
                        <h3 className="text-capitalize mb-3">精选品牌与产品<span className="text-primary-alt">“品质源于严格挑选”</span></h3>
                        <p className="fs-20 text-base">检查资质：我们严格筛选供应商，并对其生产资质和产品质量进行细致审核，确保所有合作伙伴均达到行业上游标准。</p>
                        <p className="fs-20 text-base">多样食品种类：从经典必备到新奇独特，我们涵盖各种类别，包括但不限于坚果、饮料、食品、乳制品以及各种零食和冲饮，满足不同市场和顾客的需求。</p>
                        
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </li>
            <li className="nk-schedule-item p-0">
              <div className="nk-schedule-item-inner">
                <div className="nk-schedule-symbol">
                  <NioMedia size="lg" rounded variant="warning-soft" className="fw-bold">
                    2
                  </NioMedia>
                </div>
                <div className="nk-schedule-content">
                  <Row className="flex-row-reverse justify-content-between">
                    <Col lg={7} >
                      <div className="nk-feature-overview-img text-lg-end mb-4 mb-sm-0 mb-sm-n3 mb-lg-n0 mt-lg-n9">
                        <img src="images/business-tracker/section-cover-3-a.png" alt="cover-bg" />
                        <img src="images/business-tracker/section-cover-3.png" alt="cover-fd" className="children children-center" />
                      </div>
                    </Col>
                    <Col lg={5} >
                      <div className="nk-section-head">
                        <h3 className="text-capitalize mb-3">高效采购<span className="text-warning">“质优产品，高效便捷”</span></h3>
                        <p className="fs-20 text-base">我们精心构建了覆盖全球的优质供应链网络，与世界各地的一流供应商建立了紧密的合作关系，确保每一款食品都具有无可挑剔的品质和竞争力十足。喆事宝的高效采购体系能够迅速适应市场动态，灵活调整采购策略，确保在最短的时间内满足客户多元化、个性化的需求，让客户享受到真正高效、便捷、贴心的服务体验。</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </li>
            <li className="nk-schedule-item p-0">
              <div className="nk-schedule-item-inner p-0">
                <div className="nk-schedule-symbol">
                  <NioMedia size="lg" rounded variant="danger-soft" className="fw-bold">
                    3
                  </NioMedia>
                </div>
                <div className="nk-schedule-content">
                  <Row className="flex-row-reverse justify-content-between">
                    <Col lg={7} >
                      <div className="nk-feature-overview-img text-lg-end mb-4 mb-sm-0 mb-sm-n3 mb-lg-n0 mt-lg-n9">
                        <img src="images/business-tracker/section-cover-4-a.png" alt="cover-bg" />
                        <img src="images/business-tracker/section-cover-4.png" alt="cover-fd" className="children children-center" />
                      </div>
                    </Col>
                    <Col lg={5} >
                      <div className="nk-section-head">
                        <h3 className="text-capitalize mb-4">全渠道销售<span className="text-danger">“无界限的市场接入”</span></h3>
                        <p className="fs-20 text-base">线上线下同步销售：结合传统的实体零售与现代电子商务模式，我们通过多个销售平台和渠道，包括专业的B2B平台、社交媒体营销以及我们的喆事宝平台和实体店面，实现产品的全渠道覆盖。</p>
                        <p className="fs-20 text-base">个性化客户服务：喆事宝提供定制化的销售方案，帮助合作供应商根据市场需求和目标受众，选择最合适的销售渠道和策略，确保达到最佳的销售效果。</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </li>
          </ul>
        </NioSection.Content>
      </NioSection>
      {/*  Process Section End   */}

      {/*  Funfact Section Start   */}
      <NioSection className="bg-gray-400">
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="nk-section-head text-center">
              <h2>喆事宝的承诺</h2>
              <p className="fs-20 mb-0">携手共建可持续发展的食品行业未来，我们的核心建基于客户安全及满意度，因此我们全程审慎确保产品的完整性。</p>
            </div>
          </Col>
        </Row>
        <NioSection.Content>
          <Row className="flex-row-reverse align-items-center justify-content-center justify-content-xl-between">
            <Col xl={7} >
              <div className="nk-funfact-img text-center text-xl-end mb-6 mb-xl-0">
                <img src="images/business-tracker/section-cover-5.png" alt="cover" />
              </div>
            </Col>
            <Col xl={5}>
              <Row className="gx-sm-5 gy-5">
                <Col xs={6} >
                  <div className="text-center">
                    <NioCount className="d-inline-block h1 text-purple" end={86} decimals={0} suffix="+" />
                    <div>
                      <h5>供应种类</h5>
                      <p className="fs-14">我们合作的品牌产品，涉及多领域多品种零食饮料。</p>
                    </div>
                  </div>
                </Col>
                <Col xs={6} >
                  <div className="text-center">
                    <NioCount className="d-inline-block h1 text-purple" end={195} suffix="+" />
                    <div>
                      <h5>网点</h5>
                      <p className="fs-14"> 全国城市地区合作网</p>
                    </div>
                  </div>
                </Col>
                <Col xs={6} >
                  <div className="text-center">
                    <NioCount className="d-inline-block h1 text-purple" end={5} suffix="+" />
                    <div>
                      <h5>国家地区</h5>
                      <p className="fs-14">喆事宝在食品行业经营端到端供应链</p>
                    </div>
                  </div>
                </Col>
                <Col xs={6} >
                  <div className="text-center">
                    <NioCount className="d-inline-block h1 text-purple" end={22} suffix="+" />
                    <div>
                      <h5>合作供应商</h5>
                      <p className="fs-14">我们在互相信任和专注服务的基础下致力建立长期合作关系。</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </NioSection.Content>
      </NioSection>
      {/*  Funfact Section End   */}

      {/*  Newsletter Section Start  */}
      <NioSection className="nk-newsletter-section pb-lg-0">
        <Row className="justify-content-center justify-content-lg-between align-items-center pb-5 border-bottom border-lighter">
          <Col lg={6} xl={4}>
            <div className="nk-newsletter-content text-center text-lg-start pb-5 pb-lg-0">
              <h4 className="text-capitalize">广东喆事宝贸易有限公司</h4>
              <p className="fs-14">广东省佛山市南海区桂城街道佛平四路海伦堡海汇广场5座513</p>
            </div>
          </Col>
         
        </Row>
      </NioSection>
      {/*  Newsletter Section End  */}

    </AppLayout >
  )
}

export default index;